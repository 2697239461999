<template>
  <div class="signup-main max">
    <!-- <div class="signup-image">
      <img src="../../assets/images/signup/signup-img.svg" alt="Signup Image" />
    </div> -->
    <form
      @submit="submitForm"
      class="signup-form"
      :class="{ loading: isLoading }"
    >
      <div v-show="alert.show" class="notifications-section">
        <div class="alert" :class="alert && alert.type ? alert.type : ''">
          <span class="alert-text">{{ alert.message }}</span
          ><i @click="closeAlert" class="fa-solid fa-x pointer"></i>
        </div>
      </div>
      <div class="input-wrapper">
        <input
          v-model="formData.username"
          placeholder="Username"
          class="input"
          type="text"
        />
      </div>
      <div class="input-wrapper">
        <input
          placeholder="Password"
          v-model="formData.password"
          class="input"
          type="password"
        />
      </div>

      <div class="footer">
        <span class="login-user-link"
          >Dont have an account?
          <router-link :to="{ name: 'Signup' }">Create One</router-link>
        </span>
        <button class="btn" :disabled="isLoading">
          <i class="fa-solid fa-spinner spin" v-if="isLoading"></i>
          <span class="signup-text" v-else>Login</span>
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { checkEmpty } from "../../utils/helpers";

export default {
  name: "Signup",
  metaInfo: {
    title: "The Gainer",
    titleTemplate: "%s | Login",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  data() {
    return {
      isLoading: false,
      alert: {
        type: "danger",
        message: "",
        show: false,
      },
      formData: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    closeAlert() {
      this.alert = { ...this.alert, show: false };
    },
    submitForm(e) {
      e.preventDefault();

      if (checkEmpty(this.formData.username, this.formData.password)) {
        this.alert = {
          type: "danger",
          message: "Please fill all the fields",
          show: true,
        };
        return;
      }
      this.alert = {
        type: "",
        message: "",
        show: false,
      };
      this.isLoading = true;
      let data = {
        email: this.formData.username,
        password: this.formData.password,
      };
      this.$http
        .post("/auth/login", data)
        .then((res) => {
          this.isLoading = false;
          this.alert = {
            type: "success",
            message: res.data.message,
            show: true,
          };
          const token = JSON.stringify(res.data.data.token);
          localStorage.setItem("auth_token", token);
          setTimeout(() => {
            this.$router.push({ name: "Dashboard" });
            // window.location = `${window.location.origin}/dashboard`;
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          if (!err || (!err.response && !window.navigator.onLine)) {
            this.alert = {
              type: "danger",
              message: "You are currently offline",
              show: true,
            };
            return;
          } else if (!err || !err.response) {
            this.alert = {
              type: "danger",
              message: "Something went wrong",
              show: true,
            };
            return;
          }
          if (err.response.data && err.response.data.message) {
            this.alert = {
              type: "danger",
              message: err.response.data.message,
              show: true,
            };
          } else {
            this.alert = {
              type: "danger",
              message: "Something went wrong",
              show: true,
            };
          }
        });
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
